import React from 'react';
import Lottie from 'react-lottie-player';

export interface LottieAnimationProps {
  animationData?: any;
  height?: number;
  width?: number;
  isHover?: boolean;
  frameNumber?: number;
}

export const LottieAnimation: React.FC<LottieAnimationProps> = ({
  animationData,
  height = 100,
  width = 100,
  isHover = false,
  frameNumber = 0,
}) => {
  const defaultOptions = {
    loop: true,
    goTo: frameNumber,
    play: isHover,
    animationData,
  };

  return (
    <div>
      <Lottie {...defaultOptions} style={{ width, height }} />
    </div>
  );
};
