import React, { useState } from 'react';
import styled from '@emotion/styled';
import { scroller } from 'react-scroll';
import { Container, Grid, Theme, useMediaQuery, useTheme } from '@mui/material';
import { MainPage } from '../MainPage/MainPage';
import { Hero, HeroProps } from '../../design-system/molecules/Hero';
import { ContentCard, ContentCardProps } from '../../design-system/molecules/ContentCard';
import { Typography } from '../../design-system/atoms/Typography';
import { Button } from '../../design-system/atoms/Button';
import ParticipateLogo from '../../icons/feature/ParticipateLogo';
import OrganizeLogo from '../../icons/feature/OrganizeLogo';
import PartnerLogo from '../../icons/feature/PartnerLogo';
import TezosUkraineIcon from '../../icons/FeaturedHackathonLogos/Tezos-Ukraine-Logo';
import EncodeClubIcon from '../../icons/FeaturedHackathonLogos/Encode-Club-Logo';

interface ContainerStyledProps {
  theme: Theme;
  isMain?: boolean;
}

const ContainerStyled = styled(Container)<ContainerStyledProps>`
  display: flex;
  flex-direction: column;
  margin-top: ${({ theme }) => theme.spacing(15)};
  margin-bottom: ${({ theme }) => theme.spacing(20)};

  ${({ theme, isMain }) => `${theme.breakpoints.between('sm', 'lg')} {
    ${
      isMain &&
      `      
      padding-left: ${theme.spacing(6)} !important;
      padding-right: ${theme.spacing(6)} !important;
    `
    }
  }`}

  ${({ theme, isMain }) => `${theme.breakpoints.down('sm')} {
    ${
      isMain &&
      `      
      padding-left: ${theme.spacing(2.5)} !important;
      padding-right: ${theme.spacing(2.5)} !important;
    `
    }
  }`}

  ${({ theme }) => `${theme.breakpoints.down('md')} {
    margin-top: ${theme.spacing(10)};
    margin-bottom: ${theme.spacing(15)};
  }`}
`;

const ContentCardStyled = styled(ContentCard)<{ theme?: Theme }>`
  border: none;

  ${({ theme }) => `${theme.breakpoints.down('sm')} {
      &.MuiCard-root .MuiCardMedia-root + .MuiCardContent-root {
        padding: 24px 0;
        padding-bottom: 16px !important;
      }
    
  }`}
`;

const HeroData: HeroProps = {
  title: 'Build the next big thing on Tezos',
  subtitle:
    'Whether you’re a tech builder or an advocate, take part in a hackathon and help shape the future of Tezos.',
  actions: [
    {
      label: 'Participate',
      href: 'participant',
    },
    {
      label: 'Organize',
      href: 'organizer',
    },
    {
      label: 'Collaborate',
      href: 'collaboration-application',
    },
  ],
  fullHeight: true,
  textPosition: 'center',
};

const Cards: ContentCardProps[] = [
  {
    title: 'Participate',
    mediaImage: <ParticipateLogo />,
    description:
      'Workshop your ideas, test solutions, get support for project, and become an active part of the community. Everything you need to get started is right here.',
    action: {
      title: 'Events and Resources',
      url: 'participant',
    },
    cardUrl: {
      url: 'participant',
      target: '_self',
    },
    mediaBgColor: '#233456',
  },
  {
    title: 'Organize',
    mediaImage: <OrganizeLogo />,
    description:
      'Facilitate ecosystem development, foster community, drive change in the Tezos ecosystem and beyond. Check our recommendations on how to organize a successful hacakthon and how to get funding.',
    action: {
      title: 'Starter Kit and Funding Application',
      url: 'organizer',
    },
    cardUrl: {
      url: 'organizer',
      target: '_self',
    },
    mediaBgColor: '#233456',
  },
  {
    title: 'Collaborate',
    mediaImage: <PartnerLogo />,
    description:
      'Whether you’re a university looking for research opportunities or a company looking for enterprise solutions, get in touch and find out how to get setup for a collaboration.',
    action: {
      title: 'Get in Touch',
      url: 'collaboration-application',
    },
    cardUrl: {
      url: 'collaboration-application',
      target: '_self',
    },
    mediaBgColor: '#233456',
  },
];

export const FeatureCards: ContentCardProps[] = [
  {
    title: 'Tezos DeFi Hackathon',
    description:
      'The biggest blockchain hackathon in Eastern Europe, focused on decentralized finance',
    subsections: {
      dates: '1 - 28 Feb 2022',
      organizer: 'Tezos Ukraine',
      location: 'Online',
    },
    action: {
      title: 'Learn More',
      url: 'featured/tezos-defi-hackathon',
    },
    cardUrl: {
      url: 'featured/tezos-defi-hackathon',
      target: '_self',
    },

    mediaImage: <TezosUkraineIcon />,
  },
  {
    title: 'Encode Club x Tezos Hackathon',
    description: 'An eight-week hackathon dedicated to learning how to build on Tezos',
    subsections: {
      dates: 'Mar - Jun 2022',
      organizer: 'Encode Club',
      location: 'Online',
    },
    action: {
      title: 'Learn More',
      url: 'featured/encode-club-x-tezos-hackathon',
    },
    cardUrl: {
      url: 'featured/encode-club-x-tezos-hackathon',
      target: '_self',
    },

    mediaImage: <EncodeClubIcon />,
  },
];
export interface SectionProps {
  theme: Theme;
  isMobile: boolean;
  title?: string;
  sectionIsOut?: 'Participate' | 'Organize' | 'Collaborate';
}
export const FeaturedHackathons = ({ theme, isMobile }: SectionProps): React.ReactElement => (
  <Grid container flexDirection="column" id="featured-hackathons">
    <Grid item container justifyContent="space-between" alignItems="flex-start">
      <Typography
        size={isMobile ? 'h2' : 'h1'}
        marginBottom={{ xs: 6, md: 7 }}
        className="gradient"
      >
        Featured Hackathons
      </Typography>
      {!isMobile && <Button variant="outlined" label="See All Hackathons" href="upcoming" />}
    </Grid>
    <Grid container rowSpacing={{ xs: 6, sm: 7 }} columnSpacing={3.5}>
      {FeatureCards.map((card, i) => (
        <Grid item container xs={12} md={6} key={`${card.title}-${i}`}>
          <ContentCardStyled
            {...card}
            titleColor={theme.palette.primary.main}
            mediaBgColor={`radial-gradient(${theme.palette.black.light}, ${theme.palette.black.dark})`}
          />
        </Grid>
      ))}
    </Grid>
    {isMobile && (
      <Grid item mt={{ xs: 6, sm: 7 }}>
        <Button variant="outlined" label="See All Hackathons" href="upcoming" fullWidth />
      </Grid>
    )}
  </Grid>
);

export const GetBuilding = ({
  title = 'Get Building',
  theme,
  isMobile,
  sectionIsOut,
}: SectionProps): React.ReactElement => {
  const Sections = Cards.filter((item) => item.title !== sectionIsOut);
  return (
    <Grid container flexDirection="column" mt={20}>
      <Typography
        size={isMobile ? 'h2' : 'h1'}
        marginBottom={{ xs: 6, md: 7 }}
        className="gradient"
      >
        {title}
      </Typography>
      <Grid container rowSpacing={{ xs: 6, sm: 7 }} columnSpacing={3.5} alignItems="flex-start">
        {Sections.map((card, i) => (
          <Grid item container xs={12} md={12 / Sections.length} key={`${card.title}-${i}`}>
            <ContentCardStyled
              {...card}
              mediaBgColor={`radial-gradient(${theme.palette.black.light}, ${theme.palette.black.dark})`}
            />
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

const HomePage = (): React.ReactElement => {
  const [heroHeight, setHeroHeight] = useState(0);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const fieldRef = React.useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    const myId = window.location.hash.slice(1);
    if (myId && fieldRef.current) {
      scroller.scrollTo(fieldRef.current.id, {
        duration: 1000,
        smooth: true,
        offset: -85,
      });
    }
  }, []);

  return (
    <MainPage heroSize={heroHeight}>
      <Hero
        {...HeroData}
        getHeroHeight={(h) => setHeroHeight(h)}
        fontColor={theme.palette.grey[100]}
        backgroundGradient={`linear-gradient(135deg, ${theme.palette.primary.main} 0%, ${theme.palette.purple.main} 100%)`}
      />
      <ContainerStyled maxWidth="xl" theme={theme} isMain ref={fieldRef} id="featured-container">
        <FeaturedHackathons theme={theme} isMobile={isMobile} />
        <GetBuilding theme={theme} isMobile={isMobile} />
      </ContainerStyled>
    </MainPage>
  );
};

export default HomePage;
