import * as React from 'react';

function PartnerCard({
  fill,
  fillOpacity,
  fillRule,
  clipRule,
  ...rest
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="60"
      height="88"
      viewBox="0 0 60 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M46.1538 13.6364H23.0769V0H46.1538H60V14.0152V50H46.1538V13.6364Z"
        fill={fill ?? 'white'}
        fillOpacity={fillOpacity ?? '0.5'}
      />
      <path
        d="M13.8462 36.3636H36.9231V50H13.8462H0V35.9848V0H13.8462V36.3636Z"
        fill={fill ?? 'white'}
        fillOpacity={fillOpacity ?? '0.5'}
      />
      <line x1="50" y1="25.5" x2="9" y2="25.5" stroke="#39FFC2" />
    </svg>
  );
}

const MemoPartnerCard = React.memo(PartnerCard);
export default MemoPartnerCard;
