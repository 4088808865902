import React, { useState } from 'react';
import styled from '@emotion/styled';
import { BiImage } from 'react-icons/bi';
import {
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Theme,
  useTheme,
} from '@mui/material';
import { Button } from '../../atoms/Button';
import { Typography } from '../../atoms/Typography';
import { LottieAnimation } from '../../atoms/Lottie/LottieAnimation';

const CustomCard = styled(Card)<{ theme: Theme }>`
  height: 100%;
  width: 100%;
  background-color: transparent;

  .MuiCardMedia-root {
    height: ${({ theme }) => theme.spacing(25)};
    width: 100%;
    background-color: ${({ theme }) => theme.palette.secondary.light};
    font-size: ${({ theme }) => theme.spacing(23.125)};
    color: ${({ theme }) => theme.palette.grey[100]};
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: ${({ theme }) => theme.spacing(1)};

    img {
      max-width: ${({ theme }) => theme.spacing(17)};
      max-height: ${({ theme }) => theme.spacing(17)};
    }

    div {
      display: flex;
      align-items: center;
      justify-content: center;
      width: ${({ theme }) => theme.spacing(17)};
      height: ${({ theme }) => theme.spacing(17)};
      & > svg {
        width: 100%;
        max-width: 100%;
        max-height: 100%;
      }
    }
  }

  &.usp-card {
    .MuiCardMedia-root {
      height: auto;

      div {
        width: auto;
        height: auto;
      }
    }
    ${({ theme }) => `${theme.breakpoints.down('md')} {

        .MuiCardContent-root {
          text-align: center;
        }

    }`}
  }

  &.MuiCard-root .MuiCardMedia-root + .MuiCardContent-root {
    padding: 24px 0;
  }

  &.MuiCard-root .MuiCardContent-root {
    font-weight: normal;
    flex: 1;
    width: 100%;
    box-sizing: border-box;
    padding: 32px 24px;
  }

  h5 {
    margin-bottom: 0;
  }
`;
const StyledCardActionArea = styled(CardActionArea)<{ hasUrl: boolean }>`
  position: relative;
  cursor: ${({ hasUrl }) => (hasUrl ? 'pointer' : 'default')};
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  .MuiCardActionArea-focusHighlight {
    background-color: transparent;
  }
`;

type EventDetails = {
  dates?: string;
  organizer?: string;
  location?: string;
};
export interface ContentCardProps {
  title: string | React.ReactNode;
  titleColor?: string;
  contentColor?: string;
  subsections?: EventDetails;
  description?: string;
  action?: {
    title: string;
    url: string;
  };
  hasMedia?: boolean;
  mediaImage?: string | React.ReactNode;
  mediaBgColor?: string;
  contentBgColor?: string;
  cardUrl?: {
    url: string;
    target: '_blank' | '_self';
  };
  className?: 'usp-card';
  mediaIsAnimation?: boolean;
}

export const ContentCard: React.FC<ContentCardProps> = ({
  title,
  titleColor,
  contentColor,
  subsections,
  description,
  action,
  hasMedia = true,
  mediaImage,
  mediaBgColor,
  contentBgColor,
  cardUrl,
  className,
  mediaIsAnimation,
}) => {
  const theme = useTheme();
  const [isHover, setIsHover] = useState(false);
  const [frameNumber, setFrameNumber] = useState(0);

  const clickHandler = () => {
    if (cardUrl) {
      window.open(cardUrl.url, cardUrl.target);
    }
  };

  const handleMouseLeave = () => {
    setIsHover(false);
    setFrameNumber(0);
  };

  return (
    <CustomCard
      theme={theme}
      elevation={0}
      className={className}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={handleMouseLeave}
    >
      <StyledCardActionArea disableRipple onClick={clickHandler} hasUrl={!!cardUrl ?? false}>
        {hasMedia && (
          <CardMedia
            component="div"
            style={{
              background: mediaBgColor || 'inherit',
            }}
          >
            {mediaIsAnimation && mediaImage && typeof mediaImage === 'string' ? (
              <LottieAnimation
                animationData={JSON.parse(mediaImage)}
                isHover={isHover}
                frameNumber={frameNumber}
              />
            ) : !mediaIsAnimation && mediaImage ? (
              <>
                {typeof mediaImage === 'string' ? (
                  <img src={mediaImage} alt="" />
                ) : (
                  <div>{mediaImage}</div>
                )}
              </>
            ) : (
              <BiImage />
            )}
          </CardMedia>
        )}
        <CardContent
          style={{
            backgroundColor: contentBgColor || 'inherit',
          }}
        >
          {typeof title === 'string' ? (
            <Typography size="h5" component="h5" color={titleColor}>
              {title}
            </Typography>
          ) : (
            title
          )}

          {subsections && (
            <div style={{ display: 'flex', flexDirection: 'row', marginTop: '1rem' }}>
              <Typography size="body2" color={contentColor || 'textPrimary'}>
                {Object.values(subsections).join(' \u2022 ')}
              </Typography>
            </div>
          )}

          {description && (
            <Typography
              color={contentColor || 'textSecondary'}
              component="p"
              sx={{ marginTop: 1.5 }}
            >
              {description}
            </Typography>
          )}
          {action && (
            <CardActions sx={{ padding: 0, paddingTop: 2 }}>
              <Button label={action.title} href={action.url} />
            </CardActions>
          )}
        </CardContent>
      </StyledCardActionArea>
    </CustomCard>
  );
};
