import * as React from 'react';

function ParticipateCard({
  fill,
  fillOpacity,
  fillRule,
  clipRule,
  ...rest
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="88"
      height="88"
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fillRule={fillRule ?? 'evenodd'}
        clipRule={clipRule ?? 'evenodd'}
        d="M40 40V60H20V40H0V20H20V0H40V20H60V40H40Z"
        fill={fill ?? 'white'}
        fillOpacity={fillOpacity ?? '0.5'}
      />
      <circle cx="40" cy="20" r="15.5" stroke="#39FFC2" />
    </svg>
  );
}

const MemoParticipateCard = React.memo(ParticipateCard);
export default MemoParticipateCard;
