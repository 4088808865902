import * as React from 'react';

function OrganizeCard({
  fill,
  fillOpacity,
  fillRule,
  clipRule,
  ...rest
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="60"
      height="88"
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M60 30C60 13.4375 46.5625 0 30 0V15C38.25 15 45 21.75 45 30C45 38.25 38.25 45 30 45V60C46.5625 60 60 46.5625 60 30Z"
        fill={fill ?? 'white'}
        fillOpacity={fillOpacity ?? '0.5'}
      />
      <path
        d="M15 45C23.2843 45 30 38.2843 30 30C30 21.7157 23.2843 15 15 15C6.71573 15 0 21.7157 0 30C0 38.2843 6.71573 45 15 45Z"
        fill={fill ?? 'white'}
        fillOpacity={fillOpacity ?? '0.5'}
      />
      <line x1="15" y1="29.5" x2="60" y2="29.5" stroke="#39FFC2" />
    </svg>
  );
}

const MemoOrganizeCard = React.memo(OrganizeCard);
export default MemoOrganizeCard;
